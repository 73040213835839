<template>
  <VModalWidget :dismiss="dismiss" :submit="doSubmit" :submitting="submitting" :submitTitle="$t('save')">
    <template v-slot:title>{{ $t('title') }}</template>

    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-container fluid>
          <v-row row>
            <v-col cols="12" class="pa-0">
              <LanguageField v-model="form.language" :errors="languageErrors" />
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" class="pa-0">
              <TimezoneField v-model="form.timezone" :errors="timezoneErrors" />
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" class="pa-0">
              <CountryField v-model="form.country_code" :errors="countryErrors" />
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" class="pa-0">
              <ProvinceField v-model="form.province" :country-code="form.country_code" :errors="provinceErrors" />
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="11" class="pa-0 subheading">
              {{ $t('labels.notifyOnAssignation') }}
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-switch color="success" class="pa-0 ma-0" v-model="form.notify_on_assignation" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { Modal } from '@argon/app/mixins'
import { diffObjects } from '@argon/app/helpers'
import {
  BaseForm,
  FormErrors,
  VModalWidget,
  LanguageField,
  TimezoneField,
  CountryField,
  ProvinceField
} from '@argon/app/components'
import { NAMESPACE } from '@argon/iam/me/store'

export default {
  name: 'ModalUserPreferences',
  extends: BaseForm,
  mixins: [Modal],
  components: {
    FormErrors,
    VModalWidget,
    LanguageField,
    TimezoneField,
    CountryField,
    ProvinceField
  },
  validations: {
    form: {
      language: { required },
      timezone: { required }
    }
  },
  data: () => ({
    form: {
      language: '',
      timezone: '',
      country_code: '',
      province: '',
      agency: null
    },
    submitting: false,
    returnPath: { name: 'me' }
  }),
  computed: {
    ...mapState(NAMESPACE, ['settings']),
    languageErrors() {
      const errors = []
      if (!this.$v.form.language.$dirty) return errors
      !this.$v.form.language.required && errors.push(this.$t('labels.required'))
      this.getServerErrors('language', errors)
      return errors
    },
    timezoneErrors() {
      const errors = []
      if (!this.$v.form.timezone.$dirty) return errors
      !this.$v.form.timezone.required && errors.push(this.$t('labels.required'))
      this.getServerErrors('timezone', errors)
      return errors
    },
    countryErrors() {
      const errors = []
      this.getServerErrors('country_code', errors)
      return errors
    },
    provinceErrors() {
      const errors = []
      this.getServerErrors('province', errors)
      return errors
    },
    agencyErrors() {
      const errors = []
      this.getServerErrors('agency', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['patchSettings']),
    doSubmit() {
      this.resetServerErrors()
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      let data = diffObjects(this.form, this.settings)
      this.patchSettings(data)
        .then((resp) => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.submitting = false
        })
    }
  },
  created() {
    if (this.settings) {
      this.form = { ...this.settings }
    }
  }
}
</script>

<i18n>
{
  "en": {
    "invalid": "@:labels.invalid",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "title": "Preferences"
  },
  "fr": {
    "invalid": "@:labels.invalid",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "title": "Préférences"
  }
}
</i18n>
